
import './App.css';
// import Header from './Header/Header';
import logo from './assets/logo.svg';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Des pots, de l'art et surtout des vitamines ! 
          <br/><code>🍊🍊🍊 Coming soon ! 🍊🍊🍊 ✌️ </code>
        </p>
      </header>
      {/* <Header/> */}
    </div>
  );
}

export default App;
